
const enabledPools = [
  "0.b",
  "1.b",
  "2.s",
  "3.b",
  "4.s",
  "5.s",
  "6.s",
  "7.s",
  "8.s",
  "9.s",
  "10.b",
  "11.b",
  "12.b",
  "13.b",
  "14.b",
  "15.b",
  "16.b",
  "17.b",
  "18.b",
  "19.b",
  "20.b",
  "21.b",
  "22.b",
  "23.b",
  "24.b",
  "25.b",
  "26.b",
  "27.b",
  "28.b",
  "29.b",
  "30.b",
  "31.b",
  "32.b",
  "33.b",
  "34.b",
  "35.b",
  "36.b",
  "37.b",
  "38.b",
  "39.b",
  "40.b",
  "41.b",
  "42.b",
  "43.b",
  "44.b",
  "45.b",
  "46.b",
  "47.b",
  "48.b",
  "49.b",
  "50.b",
  "51.b",
  "52.b",
  "53.b",
  "54.b",
  "55.b",
  "56.b",
  "57.s",
  "58.s",
  "59.b",
  "60.b",
  "61.b",
  "62.b",
  "63.b",
  "64.b",
  "65.b",
  "66.b",
  "67.b",
  "68.b",
  "69.b",
  "70.b",
  "71.b",
  "72.b",
  "73.b",
  "74.b",
  "75.b",
  "76.b",
  "77.b",
  "78.b",
  "79.b",
  "80.b",
  "81.b",
  "82.b",
  "83.b",
  "84.b",
  "85.b",
  "86.b",
  "87.b",
  "88.b",
  "89.b",
  "90.b",
  "91.b",
  "92.b",
  "93.b",
  "94.b",
  "95.b",
  "96.b",
  "97.b",
  //"98", (just a regular token)
  "99.b",
  "100.b",
  "101.b",
  "102.s",
  "103.s",
  "104.b",
  //"105", (not a pool)
  "106.b",
  "107.s",
  "108.s",
  "109.s",
  "110.s",
  "111.s",
  "112.s",
  "113.s",
  "114.s",
  "115.s",
  "116.s",
  "117.s",
  "118.s",
  "119.s",
  "120.s",
  "121.s",
  "122.s",
  "123.s",
  "124.s",
  "125.s",
  "126.s",
  "127.s",
  "128.s",
  "129.s",
  "130.s",
  "131.s",
  "132.s",
  "133.s",
  "134.s",
  "135.s",
  "136.s",
  "137.s",
  "138.s",
  "139.s",
  "140.s",
  "141.s",
  "142.b"
]

export default enabledPools
