
import styled from "styled-components"

import PageLayout from "./../../components/page-layout"

const UnorderedList = styled.ul`
  margin-top: -10px;
`

export default function About() {
  return (
    <PageLayout>
      <h2>
        About
      </h2>
      <p>
        stakedvalue.com is a dashboard for calculating staked value on bao.finance (xDai).
      </p>
      <p>
        This is an education tool, not financial advice and not liable for any investment
        decisions.
      </p>
      <p>
        Follow this page on twitter for updates, or send a message on twitter
        to contact the dev of this site: <a href="https://twitter.com/stakedvalue">
          https://twitter.com/stakedvalue
        </a>
      </p>
      <p>
        I also hang out on the bao.finance discord as <code>somethingElse #1655</code>
      </p>
      <p>
        Not affiliated with bao.finance.
      </p>
      <p>Donation Addresses</p>
      <UnorderedList>
        <li>ETH: 0x37172fA0316a06c6d5e2Bb00785e0f356D27CFF8</li>
        <li>xDai: 0x37172fA0316a06c6d5e2Bb00785e0f356D27CFF8</li>
        <li>BSC: 0x37172fA0316a06c6d5e2Bb00785e0f356D27CFF8</li>
      </UnorderedList>
    </PageLayout>
  )
}
