
import styled from "styled-components"

export const ContentContainer = styled.div`
  flex: 1;
  padding: 1rem;
  max-width: 700px;
`

export const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const Overview = styled.div``

export const OverviewFirstRow = styled.p`
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
`

export const OverviewRow = styled.p`
  margin-bottom: 0;
`

export const OverviewRows = styled.div`
  background: white;
  box-shadow: -5px 5px 10px #ccc;
  padding: 1rem;
  border: 1px solid #aaa;
`

export const OverviewTitle = styled.h2`
  margin-top: 0;
`

export const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
`

export const PoolInfoContainer = styled.div``

export const PoolInfoTitle = styled.h2``

export const PoolInfoTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  margin-top: 1.5rem;

  @media (max-width: 650px) {
    margin-top: 0.5rem;
  }
`
