
import { Link } from "react-router-dom"
import styled from "styled-components"

const AboutLink = styled(Link)`
  color: black;
`

const Body = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`

const BodyContent = styled.div`
  flex: 1;
  max-width: calc(650px - 2rem);
  padding: 1rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 650px) {
    padding-top: 0;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: center;
`

const HeaderContent = styled.div`
  flex: 1;
  max-width: calc(650px - 2rem);
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1rem;
`

const HeaderLeft = styled.div`
  font-style: italic;
  font-weight: bold;
`

const HeaderRight = styled.div``

const HomeLink = styled(Link)`
  color: black;
`

const PageLayoutStyled = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const TwitterLink = styled.a`
  color: black;
  padding-right: 1.6rem;
`

export default function PageLayout({children}) {
  return (
    <PageLayoutStyled>
      <Header>
        <HeaderContent>
          <HeaderLeft>
            <HomeLink to="/">
              stakedvalue.com
            </HomeLink>
          </HeaderLeft>
          <HeaderRight>
            <TwitterLink 
              href="https://twitter.com/stakedvalue"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </TwitterLink>
            <AboutLink to="/about">
              About
            </AboutLink>
          </HeaderRight>
        </HeaderContent>
      </Header>
      <Body>
        <BodyContent>
          { children }
        </BodyContent>
      </Body>
    </PageLayoutStyled>
  )
}
