
import Web3 from "web3"
import { ConnectWalletButtonStyled } from "./styles"

export default function ConnectWalletButton({onObtainAddress}) {

  const onBoxClick = () => {
    const web3 = new Web3(window.ethereum)
    window.ethereum.enable().then(() => {
      web3.eth.getAccounts().then(accounts => {
        onObtainAddress(accounts[0])
      })
    })
  }

  return (
    <ConnectWalletButtonStyled onClick={onBoxClick}>
      Connect Wallet
    </ConnectWalletButtonStyled>
  )
}
