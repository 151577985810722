
export const formatNumber = (num, decimalPlaces) => {
  if (!num) {
    return 0;
  }
  return num.toLocaleString(undefined, {
    minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces
  })
}

// an opinionated function on how many decimal
// places are needed
export const getDecimalPlaces = (num) => {
  const numAbsVal = Math.abs(num)
  if (numAbsVal >= 1) {
    return 2
  }
  if (numAbsVal >= 0.1) {
    return 3
  }
  if (numAbsVal >= 0.01) {
    return 4
  }
  if (numAbsVal >= 0.001) {
    return 5
  }
  if (numAbsVal >= 0.0001) {
    return 6
  }
  if (numAbsVal >= 0.00001) {
    return 7
  }
  if (numAbsVal >= 0.000001) {
    return 8
  }
  if (numAbsVal >= 0.0000001) {
    return 9
  }
  return 10
}

export const getPairName = (poolObject) => {
  const symbols = []
  poolObject.balances.forEach(balance => {
    const { symbol } = balance
    if (symbol === "WXDAI") {
      symbols.push("XDAI")
    } else {
      symbols.push(symbol)
    }
  })

  let pairName = symbols.join('-')
  if (poolObject.exchangeLp === "Sushi") {
    pairName += " (SLP)"
  }
  
  return pairName
}

export const getTotalLpValue = poolObject => {
  return poolObject.balances.reduce((acc, cur) => {
    return acc + (cur.balanceNoDecimal * cur.symbolPrice)
  }, 0)
}

export const getValueOfLpTokens = (poolObject) => {
  const { lpTokensStaked, totalLpTokenSupply } = poolObject
  const pricePerLp = getTotalLpValue(poolObject) / totalLpTokenSupply
  return pricePerLp * lpTokensStaked
}
