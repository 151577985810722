import { useState } from "react"
import Web3 from "web3"
import { Helmet } from "react-helmet"
import baoMasterFarmerAbi from "../../constants/abis/bao-master-farmer.json"
import PageLayout from "./../../components/page-layout"

import { PoolLookup, 
  PoolLookupInput, PoolLookupInputRow, PoolLookupResult, ResultsTitle } from "./styles"
import "./styles.css"

const baoMasterFarmerAddress = "0xf712a82DD8e2Ac923299193e9d6dAEda2d5a32fd"
const xdaiRemoteNode = "wss://xdai.poanetwork.dev/wss"

export default function DevTools() {
  const [poolId, setPoolId] = useState(1)
  const [lpTokenAddress, setLpTokenAddress] = useState(null)

  const getTokenBalanceURL = address => {
    return `https://blockscout.com/poa/xdai/api?module=account&action=tokenlist&address=${address}`
  }

  const getTokenInfoURL = address => {
    return `https://blockscout.com/poa/xdai/api?module=token&action=getToken`
      + `&contractaddress=${address}`
  }

  const onPoolIdChange = e => {
    setPoolId(e.target.value)
  }

  const onPoolLookup = () => {
    const web3 = new Web3(new Web3.providers.WebsocketProvider(xdaiRemoteNode))
    const masterFarmerContract = new web3.eth.Contract(baoMasterFarmerAbi, baoMasterFarmerAddress)
    masterFarmerContract.methods.poolInfo(poolId).call().then(res => {
      setLpTokenAddress(res["lpToken"])
    })
  }

  return (
    <PageLayout>
      <Helmet>
        <title>Staked Value: Dev Tools</title>
      </Helmet>
      <h2>
        Dev Tools
      </h2>
      <p>
        If you're seeing this page, it's just a bunch of random
        tools I use to develop the site. Not really public, but feel
        free to use the tools if it helps.
      </p>
      <h3>
        Get Pool Info
      </h3>
      <PoolLookup>
        <PoolLookupInputRow>
          <span>Pool ID</span>
          <PoolLookupInput
            onChange={onPoolIdChange}
            value={poolId}
            type="number"
          />
          <button onClick={onPoolLookup}>
            Look Up
          </button>
        </PoolLookupInputRow>
        <PoolLookupResult>
          {
            !lpTokenAddress && <p>Nothing searched yet</p>
          }
          {
            lpTokenAddress &&
            <div>
              <ResultsTitle>Results</ResultsTitle>
              <p>LP Token Address: {lpTokenAddress}</p>
              <p>
                <a 
                  href={getTokenBalanceURL(lpTokenAddress)}
                  target="_blank" 
                  rel="noreferrer"
                >
                  See token balances (blockscout api)
                </a>
              </p>
              <p>
                <a 
                  href={getTokenInfoURL(lpTokenAddress)}
                  target="_blank" 
                  rel="noreferrer"
                >
                  See token info (blockscout api)
                </a>
              </p>
              <p>
                <a
                  target="_blank" 
                  rel="noreferrer"
                  href={`https://blockscout.com/poa/xdai/address/${lpTokenAddress}`}
                >
                  See token page (blockscout page)
                </a>
              </p>
            </div>
          }
        </PoolLookupResult>
      </PoolLookup>
    </PageLayout>
  )
}