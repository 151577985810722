import { useEffect, useState } from "react"
import Web3 from "web3"
import { useLocation } from "react-router-dom"

import enabledPools from "./../../config/bao-finance-xdai/enabled-pools"
import baoMasterFarmerAbi from "../../constants/abis/bao-master-farmer.json"
import Loading from "./../../components/loading"
import PoolList from "./../../components/pool-list"
import PageLayout from "./../../components/page-layout"

import { assignBalanceSymbolPrice, assignForeignAddress, assignLpTokenAddresses, 
  assignLpTokenBalances, assignLpTokenSupply, 
  assignPendingReward, 
  cleanPoolObjectBalances, filterOutUnusedPools,
  transformToPoolObjects } from "./../../lib/pool-objects.js"

import { LoadingContainer, PoolInfoContainer, PoolInfoTitle, 
  PoolInfoTitleRow } from "./styles"

const baoMasterFarmerAddress = "0xf712a82DD8e2Ac923299193e9d6dAEda2d5a32fd"
const zeroAddress = "0x0000000000000000000000000000000000000000"
const ethRemoteNode = "wss://mainnet.infura.io/ws/v3/84f1a0360cd04fb6bc0a54e088b38878"
const xdaiRemoteNode = "wss://xdai.poanetwork.dev/wss"

const assignPoolObjectValues = async (poolObjects) => {
  const web3 = new Web3(new Web3.providers.WebsocketProvider(xdaiRemoteNode))
  const web3Eth = new Web3(new Web3.providers.WebsocketProvider(ethRemoteNode))
  const masterFarmerContract = new web3.eth.Contract(baoMasterFarmerAbi, baoMasterFarmerAddress)

  poolObjects = await assignPoolStakingAmountSim(poolObjects)

  poolObjects = filterOutUnusedPools(poolObjects)

  poolObjects = await assignLpTokenAddresses(masterFarmerContract, poolObjects)
  
  poolObjects = await assignForeignAddress(web3, poolObjects)

  poolObjects = await assignPendingReward(masterFarmerContract, poolObjects, zeroAddress)
  
  poolObjects = await assignLpTokenSupply(web3, web3Eth, poolObjects)

  poolObjects = await assignLpTokenBalances(web3Eth, poolObjects)

  poolObjects = cleanPoolObjectBalances(poolObjects)
  
  const pricesRes = await assignBalanceSymbolPrice(poolObjects)
  poolObjects = pricesRes[0]

  return new Promise(resolve => {
    resolve([poolObjects]) 
  })
}

const assignPoolStakingAmountSim = poolObjects => {
  return poolObjects.map(poolObject => {
    return {
      ...poolObject,
      lpTokensStaked: 1.0
    }
  })
}

const filterEnabledPools = (poolNames, minPoolId, maxPoolId) => {
  const res = []
  minPoolId = Number(minPoolId)
  maxPoolId = Number(maxPoolId)

  poolNames.forEach(poolName => {
    const poolId = poolName.split('.')[0]
    if (poolId >= minPoolId && poolId <= maxPoolId) {
      res.push(poolName)
    }
  })

  return res
}

export default function BaoFinanceXdaiSim() {
  const [isLoading, setIsLoading] = useState(true)
  const [poolObjects, setPoolObjects]  = useState([])
  const query = new URLSearchParams(useLocation().search)
  const minId = query.get("minId") || 0
  const maxId = query.get("maxId") || 142

  useEffect(() => {
    const poolObjects = transformToPoolObjects(filterEnabledPools(enabledPools, minId, maxId))
    assignPoolObjectValues(poolObjects).then(res => {
      const poolObjectsRes = res[0]
      setPoolObjects(poolObjectsRes)
      setIsLoading(false)
    })
  }, [minId, maxId])

  const existPoolObjects = poolObjects.length > 0

  return (
    <PageLayout>
      { 
        isLoading &&
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      }
      {
        !isLoading &&
        <PoolInfoContainer>
          <PoolInfoTitleRow>
            <PoolInfoTitle>Active Pools</PoolInfoTitle>
          </PoolInfoTitleRow>
          {
            !existPoolObjects && <p>No active pools found</p>
          }
          {
            existPoolObjects && 
            <PoolList 
              poolObjects={poolObjects} 
              baoPrice={.001}
            />
          }
        </PoolInfoContainer>
      }
    </PageLayout>
  )
}
