
import styled from "styled-components"

export const AddressInput = styled.input`
  width: 380px;
  padding: 0.5rem;

  @media (max-width: 650px) {
    width: 100%;
  }
`

export const AddressSearchButton = styled.div`
  border: 1px solid #ccc;
  margin-left: 1rem;
  padding: 0.5rem;
  background-color: #fafafa;
  width: 80px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 650px) {
    margin-top: 1.5rem;
    margin-left: 0;
  }
`

export const OrText = styled.p``

export const WalletInputBox = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  background-color: white;
  box-shadow: -5px 5px 5px #ccc;
  margin-bottom: 2.5rem;

  @media (max-width: 650px) {
    border: none;
    background-color: #fff8ef;
    box-shadow: none;
    margin-bottom: 0;
  }
`

export const WalletInputBoxContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const WalletInputBoxInfo = styled.p`
  text-align: center;
  margin-top: 0;
  max-width: 400px;
  padding-bottom: 2rem;
`

export const WalletInputBoxSearchRow = styled.div`
  display: flex;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`

export const WalletInputBoxTitle = styled.h1`
  text-align: center;
  font-style: italic;
  max-width: 300px;
`
