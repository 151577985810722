
import styled from "styled-components"

export const ContentContainer = styled.div`
  flex: 1;
  padding: 1rem;
  max-width: 700px;
`

export const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
`

export const PoolInfoContainer = styled.div`
`

export const PoolInfoTitle = styled.h2``

export const PoolInfoTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
`
