import styled from "styled-components"

export const PoolListStyled = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 2rem;
  }

  > div:last-child {
    margin-bottom: 1rem;
  }
`
