
import PoolInfo from "./../pool-info"

import { PoolListStyled } from "./styles"

export default function PoolList({poolObjects, baoPrice}) {
  return (
    <PoolListStyled>
      {
        poolObjects.map((poolObject, i) => {
          return (
            <PoolInfo
              baoPrice={baoPrice}
              poolObject={poolObject}
              key={i} 
            />
          )
        })
      }
    </PoolListStyled>
  )
}