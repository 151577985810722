
import { useEffect, useState } from "react"

import { LoadingInfo, LoadingStyled, LoadingText } from "./styles"

const getDots = ticks => {
  return '.'.repeat(ticks % 3)
}

export default function Loading() {
  const [ticks, setTicks] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setTicks(ticks => ticks + 1)
    }, 500)
    return () => clearInterval(interval)
  }, [])

  return (
    <LoadingStyled>
      <LoadingText>
        {`Loading.${getDots(ticks)}`}
      </LoadingText>
      <LoadingInfo shown={ticks > 10}>
        (loading may take up to a minute at times)
      </LoadingInfo>
    </LoadingStyled>
  )
}