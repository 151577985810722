import Main from './views/main'
import BaoFinanceXdai from './views/bao-finance-xdai'
import BaoFinanceXdaiSim from './views/bao-finance-xdai-sim'
import DevTools from './views/dev-tools'
import About from "./views/about"
import {HashRouter as Router, Switch, Route} from "react-router-dom"
import './App.css'

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/bao-finance-xdai/:walletAddress">
          <BaoFinanceXdai />
        </Route>
        <Route path="/bao-finance-xdai-sim">
          <BaoFinanceXdaiSim />
        </Route>
        <Route path="/dev-tools">
          <DevTools />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/">
          <Main />
        </Route>
      </Switch>
    </Router>
  );
}

export default App
