
import { useState } from "react"
import { formatNumber, getDecimalPlaces, getPairName, 
  getTotalLpValue, getValueOfLpTokens } from "./helpers"

import { InfoRow, MoreInfo, PairName,
  PoolInfoStyled, ShowMoreInfoRow } from "./styles"

const getSymbolName = (symbol) => {
  if (symbol === "WXDAI") {
    return "XDAI"
  }
  return symbol
}

const getLpEquivalentOf = (poolObject) => {
  const res = []
  const valueOfLpTokens = getValueOfLpTokens(poolObject)
  const totalLpValue = getTotalLpValue(poolObject)
  const pctPool = valueOfLpTokens / totalLpValue

  poolObject.balances.forEach(balance => {
    const balanceEquivalent = balance.balanceNoDecimal * pctPool
    const equivalentDecimalPlaces = getDecimalPlaces(balanceEquivalent)
    res.push(`${formatNumber(balanceEquivalent, equivalentDecimalPlaces)} ${balance.symbol}`)
  })

  return res.join(' and ')
}

export default function PoolInfo({poolObject, baoPrice}) {
  const [showMoreInfo, setShowMoreInfo] = useState(false)

  const { lpTokensStaked } = poolObject
  const pairName = getPairName(poolObject)
  const valueOfLpTokens = getValueOfLpTokens(poolObject)
  const totalLpValue = getTotalLpValue(poolObject)
  const pendingReward = poolObject["pendingReward"] / 10 ** 18
  const stakedLpDecimalPlaces = getDecimalPlaces(lpTokensStaked)
  const pendingRewardValue = pendingReward * baoPrice
  const totalValue = valueOfLpTokens + pendingRewardValue

  return (
    <PoolInfoStyled>
      <PairName>
        { pairName } - ${formatNumber(totalValue, 2)}
      </PairName>
      <InfoRow>
        LP Staked: {`${formatNumber(lpTokensStaked, stakedLpDecimalPlaces)} ($${formatNumber(valueOfLpTokens, 2)})`}
      </InfoRow>
      <InfoRow>
        Pending BAO: {`${formatNumber(pendingReward, 2)} ($${formatNumber(pendingRewardValue, 2)})`}
      </InfoRow>
      <InfoRow>
        {`LP Equivalent: ${getLpEquivalentOf(poolObject)}`}
      </InfoRow>
      {
        !showMoreInfo &&
        <ShowMoreInfoRow onClick={() => setShowMoreInfo(true)}>
          Show More Info
        </ShowMoreInfoRow>
      }
      {
        showMoreInfo && 
        <MoreInfo>
          <ShowMoreInfoRow onClick={() => setShowMoreInfo(false)}>
            Show Less Info
          </ShowMoreInfoRow>
          {
            poolObject.balances.map((balance, i) => {
              const symbolName = getSymbolName(balance["symbol"])
              return (
                <div key={i}>
                  <InfoRow>
                    {`${symbolName} Price: $${balance["symbolPrice"]}`}
                  </InfoRow>
                  <InfoRow>
                    {`${symbolName} in Liquidity Pool: ${formatNumber(balance["balanceNoDecimal"], 2)}`}
                  </InfoRow>
                </div>
              )
            })
          }
          <InfoRow>
            {`Total LP Token Supply: ${formatNumber(poolObject["totalLpTokenSupply"], 2)}`}
          </InfoRow>
          <InfoRow>
            {`Total Liquidity Pool Value: $${formatNumber(totalLpValue, 2)}`}
          </InfoRow>
          {
            poolObject.exchangeLp !== "Sushi" && 
            <InfoRow>
              <a 
                href={`https://blockscout.com/poa/xdai/address/${poolObject["lpTokenAddress"]}`}
                target="_blank"
                rel="noreferrer"
              >
                LP Token Page on Blockscout
              </a>
            </InfoRow>
          }
          {
            poolObject.exchangeLp === "Sushi" && 
            <InfoRow>
              <a 
                href={`https://etherscan.io/address/${poolObject["foreignTokenAddress"]}`}
                target="_blank"
                rel="noreferrer"
              >
                LP Token Page on Etherscan
              </a>
            </InfoRow>
          }
        </MoreInfo>
      }
    </PoolInfoStyled>
  )
}