import { useState } from "react"
import { useHistory } from "react-router-dom"
import ConnectWalletButton from "../../components/connect-wallet-button"
import PageLayout from "../../components/page-layout"
import { AddressInput, AddressSearchButton, OrText,
  WalletInputBox, WalletInputBoxContainer, WalletInputBoxInfo, 
  WalletInputBoxSearchRow, WalletInputBoxTitle } from "./styles"

export default function InitialView() {
  const history = useHistory()
  const [addressInput, setAddressInput] = useState('')

  const onObtainAddress = address => {
    history.push(`/bao-finance-xdai/${address}`)
  }

  const onSearchAddress = () => {
    if (!addressInput) {
      return;
    }
    history.push(`/bao-finance-xdai/${addressInput}`)
  }

  return (
    <PageLayout>
      <WalletInputBoxContainer>
        <WalletInputBox>
          <WalletInputBoxTitle>
            bao.finance (xDai) staking dashboard
          </WalletInputBoxTitle>
          <WalletInputBoxInfo>
            See your staked value of LPs / pending bao / locked bao
          </WalletInputBoxInfo>
          <WalletInputBoxSearchRow>
            <AddressInput
              type="text"
              placeholder="wallet address"
              value={addressInput}
              onChange={e => setAddressInput(e.target.value)}
            />
            <AddressSearchButton onClick={onSearchAddress}>
              Search  
            </AddressSearchButton>
          </WalletInputBoxSearchRow>
          <OrText>
            or
          </OrText>
          <ConnectWalletButton onObtainAddress={onObtainAddress}/>
        </WalletInputBox>
      </WalletInputBoxContainer>
    </PageLayout>
  )
}
