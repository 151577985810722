import styled from "styled-components"

export const InfoRow = styled.p`
  margin-bottom: 0;
`

export const MoreInfo = styled.div`
  border-top: 1px solid #ccc;
  margin-top: 1rem;
  font-size: 0.9rem;
`

export const PairName = styled.div`
  font-weight: bold;
`

export const PoolInfoStyled = styled.div`
  border: 1px solid #aaa;
  padding: 1rem;
  box-shadow: -5px 5px 10px #ccc;
  background-color: white;
`

export const ShowMoreInfoRow = styled.div`
  color: dodgerblue;
  margin-top: 1rem;
  font-size: 0.8rem;

  &:hover {
    cursor: pointer;
  }
`
