import { useEffect, useState } from "react"
import Web3 from "web3"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet"

import enabledPools from "./../../config/bao-finance-xdai/enabled-pools"
import baoMasterFarmerAbi from "../../constants/abis/bao-master-farmer.json"
import Loading from "./../../components/loading"
import PoolList from "./../../components/pool-list"
import PageLayout from "./../../components/page-layout"

import { assignBalanceSymbolPrice, assignForeignAddress, assignLpTokenAddresses, 
  assignLpTokenBalances, assignLpTokenSupply, 
  assignPendingReward, assignPoolStakingAmount,
  cleanPoolObjectBalances, fetchTotalBaoLocked, filterOutUnusedPools,
  transformToPoolObjects } from "./../../lib/pool-objects.js"

import { LoadingContainer, Overview, OverviewFirstRow, OverviewRow, OverviewRows, OverviewTitle,
  PoolInfoContainer, PoolInfoTitle, PoolInfoTitleRow } from "./styles"

  import { formatNumber, getValueOfLpTokens } from "../../components/pool-info/helpers"

const baoMasterFarmerAddress = "0xf712a82DD8e2Ac923299193e9d6dAEda2d5a32fd"
const ethRemoteNode = "wss://mainnet.infura.io/ws/v3/84f1a0360cd04fb6bc0a54e088b38878"
const xdaiRemoteNode = "wss://xdai.poanetwork.dev/wss"

const assignPoolObjectValues = async (poolObjects, walletAddress) => {
  const web3 = new Web3(new Web3.providers.WebsocketProvider(xdaiRemoteNode))
  const web3Eth = new Web3(new Web3.providers.WebsocketProvider(ethRemoteNode))
  const masterFarmerContract = new web3.eth.Contract(baoMasterFarmerAbi, baoMasterFarmerAddress)

  poolObjects = await assignPoolStakingAmount(masterFarmerContract, poolObjects, walletAddress)
  
  poolObjects = filterOutUnusedPools(poolObjects)

  poolObjects = await assignLpTokenAddresses(masterFarmerContract, poolObjects)
  
  poolObjects = await assignForeignAddress(web3, poolObjects)

  poolObjects = await assignPendingReward(masterFarmerContract, poolObjects, walletAddress)
  
  poolObjects = await assignLpTokenSupply(web3, web3Eth, poolObjects)

  poolObjects = await assignLpTokenBalances(web3Eth, poolObjects)

  poolObjects = cleanPoolObjectBalances(poolObjects)

  const pricesRes = await assignBalanceSymbolPrice(poolObjects)
  poolObjects = pricesRes[0]
  const baoPrice = pricesRes[1]
  
  const totalLockedBao = await fetchTotalBaoLocked(web3, walletAddress)

  return new Promise(resolve => {
    resolve([poolObjects, totalLockedBao, baoPrice]) 
  })
}

export default function BaoFinanceXdai() {
  const { walletAddress } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [poolObjects, setPoolObjects]  = useState([])
  const [totalLockedBao, setTotalLockedBao] = useState(0)
  const [baoPrice, setBaoPrice] = useState(0)

  useEffect(() => {
    const poolObjects = transformToPoolObjects(enabledPools)
    assignPoolObjectValues(poolObjects, walletAddress)
      .then(res => {
        const poolObjectsRes = res[0]
        const totalLockedBao = res[1]
        setPoolObjects(poolObjectsRes)
        setTotalLockedBao(totalLockedBao / 10 ** 18)
        setIsLoading(false)
        setBaoPrice(res[2])
      })
  }, [walletAddress])

  const existPoolObjects = poolObjects.length > 0

  const totalLockedValue = totalLockedBao * baoPrice

  const totalStakedValue = poolObjects.reduce((acc, cur) => {
    return acc + getValueOfLpTokens(cur)
  }, 0)

  const totalPendingBao = poolObjects.reduce((acc, cur) => {
    return acc + (cur.pendingReward / 10 ** 18)
  }, 0)

  const totalPendingValue = totalPendingBao * baoPrice;

  const totalPortfolioValue = totalStakedValue + totalLockedValue + totalPendingValue;

  return (
    <PageLayout>
      <Helmet>
        <title>Staked Value: bao.finance (xdai)</title>
      </Helmet>
      { 
        isLoading &&
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      }
      {
        !isLoading &&
        <PoolInfoContainer>
          <Overview>
            <OverviewTitle>
              Overview
            </OverviewTitle>
            <OverviewRows>
              <OverviewFirstRow>
              {`All Pools - $${formatNumber(totalPortfolioValue, 2)}`}
              </OverviewFirstRow>
              <OverviewRow>
                {`Value of All Staked LP: $${formatNumber(totalStakedValue, 2)}`}
              </OverviewRow>
              <OverviewRow>
                {`Total Locked BAO: ${formatNumber(totalLockedBao, 2)} ($${formatNumber(totalLockedValue, 2)})`}
              </OverviewRow>
              <OverviewRow>
                {`Total Pending BAO: ${formatNumber(totalPendingBao, 2)} ($${formatNumber(totalPendingValue, 2)})`}
              </OverviewRow>
              <OverviewRow>
                {`BAO Price: $${formatNumber(baoPrice, 6)}`}
              </OverviewRow>
            </OverviewRows>
          </Overview>
          <PoolInfoTitleRow>
            <PoolInfoTitle>Active Pools</PoolInfoTitle>
          </PoolInfoTitleRow>
          {
            !existPoolObjects && <p>No active pools found</p>
          }
          {
            existPoolObjects && 
            <PoolList 
              poolObjects={poolObjects} 
              baoPrice={baoPrice} 
            />
          }
        </PoolInfoContainer>
      }
    </PageLayout>
  )
}
