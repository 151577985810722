
import styled from "styled-components"

export const ConnectWalletButtonStyled = styled.div`
  padding: 1rem 2rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fafafa;
  text-align: center;
  max-width: 120px;
  margin-top: 0.5em;

  &:hover {
    cursor: pointer;
  }
`
