
import axios from "axios"
import symbolToCgId from "../constants/apis/symbol-to-cg-id.json"
import v1ExchangeAbi from "../constants/abis/v1-exchange.json"
import slpAbi from "../constants/abis/slp.json"
import omnibridgeMediatorAbi from "../constants/abis/omnibridge-mediator.json"
import erc20Abi from "../constants/abis/erc-20.json"
import baoCxAbi from "../constants/abis/bao-cx.json"

const baoCxAddress = "0xe0d0b1DBbCF3dd5CAc67edaf9243863Fd70745DA"
const tokenBalancesApiFmt = "https://blockscout.com/poa/xdai/api?module=account&action=tokenlist&address="
const omniBridgeMediatorAddress = "0xf6A78083ca3e2a662D6dd1703c939c8aCE2e268d"

const fetchTotalSupplyOfLp = (web3, web3Eth, poolObject) => {
  if (poolObject.exchangeLp === "Baoswap") {
    const contract = new web3.eth.Contract(v1ExchangeAbi, poolObject["lpTokenAddress"])
    return new Promise((resolve) => {
      contract.methods.decimals().call().then(decimals => {
        contract.methods.totalSupply().call().then(totalSupply => {
          resolve(totalSupply / 10 ** decimals)
        })
      })
    })
  }

  return new Promise(resolve => {
    const contract = new web3Eth.eth.Contract(slpAbi, poolObject["foreignTokenAddress"])
    contract.methods.decimals().call().then(decimals => {
      contract.methods.totalSupply().call().then(totalSupply => {
        resolve(totalSupply / 10 ** decimals)
      })
    })
  })
}

const fetchLpTokenBalances = (web3Eth, poolObject) => {
  if (poolObject.exchangeLp === "Baoswap") {
    const { lpTokenAddress } = poolObject
    const apiUrl = tokenBalancesApiFmt + lpTokenAddress
    return new Promise(resolve => {
      axios.get(apiUrl).then(res => {
        resolve(res.data.result)
      })
    })
  }

  const { foreignTokenAddress } = poolObject
  const contract = new web3Eth.eth.Contract(slpAbi, foreignTokenAddress)

  // need to add this cause MKR is an asshole
  const getSymbol0 = (tokenContract, tokenAddress) => {
    return new Promise(resolve => {
      if (tokenAddress === "0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2") {
        resolve("MKR")
        return
      }
      tokenContract.methods.symbol().call().then(symbol => {
        resolve(symbol)
      })
    })
  }
  
  return new Promise(resolve => {
    contract.methods.getReserves().call().then(reserves => {
      contract.methods.token0().call().then(tokenAddress0 => {
        contract.methods.token1().call().then(tokenAddress1 => {
          const token0Contract = new web3Eth.eth.Contract(erc20Abi, tokenAddress0)
          const token1Contract = new web3Eth.eth.Contract(erc20Abi, tokenAddress1)
          getSymbol0(token0Contract, tokenAddress0).then(symbol0 => {
            token0Contract.methods.decimals().call().then(decimals0 => {
              token1Contract.methods.symbol().call().then(symbol1 => {
                token1Contract.methods.decimals().call().then(decimals1 => {
                  resolve([
                    {
                      balance: reserves["_reserve0"],
                      decimals: decimals0,
                      symbol: symbol0
                    },
                    {
                      balance: reserves["_reserve1"],
                      decimals: decimals1,
                      symbol: symbol1
                    }
                  ])
                })
              })
            })
          })
        })
      })
    })
  })
}

const fetchPrices = symbols => {
  const cgIds = []
  symbols.forEach(symbol => {
    const cgId = symbolToCgId[symbol]
    if (cgId === 1) {
      return
    }
    cgIds.push(cgId)
  })
  const uniqueCgIds = [...new Set(cgIds)]
  const uniqueCgIdsFmt = uniqueCgIds.join(',')
  const url = `https://api.coingecko.com/api/v3/simple/price?ids=${uniqueCgIdsFmt}&vs_currencies=usd`
  
  return new Promise((resolve, reject) => {
    axios.get(url).then(res => {
      const prices = {}
      symbols.forEach(symbol => {
        const cgId = symbolToCgId[symbol]
        if (cgId === 1) {
          prices[symbol] = 1
          return
        }
        if (!cgId) {
          console.log(symbol);
        }
        prices[symbol] = res.data[cgId].usd
      })
      resolve(prices)
    })
  })
}

export const assignBalanceSymbolPrice = (poolObjects) => {
  const allSymbols = poolObjects.reduce((acc, cur) => {
    const poolObjectSymbols = []
    cur.balances.forEach(balance => {
      poolObjectSymbols.push(balance.symbol)
    })
    return acc.concat(poolObjectSymbols)
  }, [])
  allSymbols.push("BAO")
  const allUniqueSymbols = [...new Set(allSymbols)]

  return new Promise((resolve, reject) => {
    fetchPrices(allUniqueSymbols).then(prices => {
      const res = []
      poolObjects.forEach(poolObject => {
        const oldBalances = poolObject.balances
        const newBalances = []
        oldBalances.forEach(balance => {
          newBalances.push({
            ...balance,
            symbolPrice: prices[balance["symbol"]]
          })
        })
        res.push({
          ...poolObject,
          balances: newBalances
        })
      })
      resolve([res, prices["BAO"]])
    })
  })
}

export const assignForeignAddress = (web3, poolObjects) => {
  const promises = []
  const contract = new web3.eth.Contract(omnibridgeMediatorAbi, omniBridgeMediatorAddress)

  for(let i = 0; i < poolObjects.length; i++) {
    const { exchangeLp, lpTokenAddress } = poolObjects[i]
    if (exchangeLp !== "Sushi") {
      continue;
    }
    promises.push(contract.methods.foreignTokenAddress(lpTokenAddress).call())
  }

  return new Promise((resolve) => {
    if (promises.length === 0) {
      resolve(poolObjects)
      return
    }

    Promise.all(promises).then(values => {
      const res = []
      let currentIdx = 0

      poolObjects.forEach(poolObject => {
        const newPoolObject = {...poolObject}
        if (poolObject.exchangeLp === "Sushi") {
          newPoolObject["foreignTokenAddress"] = values[currentIdx]
          currentIdx++
        }
        res.push(newPoolObject)
      })

      resolve(res)
    })
  })
}

export const assignLpTokenAddresses = (masterFarmerContract, poolObjects) => {
  const promises = []

  for(let i = 0; i < poolObjects.length; i++) {
    const { poolId } = poolObjects[i]
    promises.push(masterFarmerContract.methods.poolInfo(poolId).call())
  }

  return new Promise((resolve, reject) => {
    Promise.all(promises).then(values => {
      const res = []
      for(let i = 0; i < poolObjects.length; i++) {
        res.push({
          ...poolObjects[i],
          lpTokenAddress: values[i]["lpToken"]
        })
      }
      resolve(res)
    })
  })
}

export const assignLpTokenBalances = (web3Eth, poolObjects) => {
  const promises = []
  
  for(let i = 0; i < poolObjects.length; i++) {
    promises.push(fetchLpTokenBalances(web3Eth, poolObjects[i]))
  }

  return new Promise((resolve, reject) => {
    Promise.all(promises).then(values => {
      const res = []
      for(let i = 0; i < poolObjects.length; i++) {
        res.push({
          ...poolObjects[i],
          balances: values[i]
        })
      }
      resolve(res)
    })
  })
}

export const assignLpTokenSupply = (web3, web3Eth, poolObjects) => {
  const promises = []
  
  for(let i = 0; i < poolObjects.length; i++) {
    promises.push(fetchTotalSupplyOfLp(web3, web3Eth, poolObjects[i]))
  }

  return new Promise((resolve) => {
    Promise.all(promises).then(values => {
      const res = []
      for(let i = 0; i < poolObjects.length; i++) {
        res.push({
          ...poolObjects[i],
          totalLpTokenSupply: values[i]
        })
      }
      resolve(res)
    })
  })
}

export const assignPendingReward = (masterFarmerContract, poolObjects, walletAddress) => {
  const promises = []
  
  for(let i = 0; i < poolObjects.length; i++) {
    const { poolId } = poolObjects[i]
    promises.push(masterFarmerContract.methods.pendingReward(poolId, walletAddress).call())
  }

  return new Promise((resolve, reject) => {
    Promise.all(promises).then(values => {
      const res = []
      for(let i = 0; i < poolObjects.length; i++) {
        res.push({
          ...poolObjects[i],
          pendingReward: values[i]
        })
      }
      resolve(res)
    })
  })
}

export const assignPoolStakingAmount = (masterFarmerContract, poolObjects, walletAddress) => {
  const promises = []

  poolObjects.forEach(poolObject => {
    const { poolId } = poolObject
    promises.push(masterFarmerContract.methods.userInfo(poolId, walletAddress).call())
  })

  return new Promise((resolve, reject) => {
    Promise.all(promises).then(values => {
      const res = []
      for(let i = 0; i < poolObjects.length; i++) {
        res.push({
          ...poolObjects[i],
          lpTokensStaked: values[i]["amount"] / 10 ** 18
        })
      }
      resolve(res)
    })
  })
}

export const cleanPoolObjectBalances = poolObjects => {
  return poolObjects.map(poolObject => {
    const oldBalances = poolObject.balances
    const newBalances = []
    oldBalances.forEach(balance => {
      if (balance.symbol === "XGT" || balance.symbol === "LEVIN" || balance.symbol === "ESR" || balance.symbol === "BAO-V2") {
        return
      }
      newBalances.push({
        ...balance,
        balanceNoDecimal: balance.balance / 10 ** balance.decimals
      })
    })
    return {
      ...poolObject,
      balances: newBalances
    }
  })
}

export const fetchTotalBaoLocked = (web3, walletAddress) => {
  const contract = new web3.eth.Contract(baoCxAbi, baoCxAddress)
  return contract.methods.lockOf(walletAddress).call()
}

export const filterOutUnusedPools = poolObjects => {
  return poolObjects.filter(poolObject => {
    return poolObject.lpTokensStaked !== 0
  })
}

export const transformToPoolObjects = (poolFullNames) => {
  return poolFullNames.map(poolFullName => {
    return {
      poolId: poolFullName.split('.')[0],
      exchangeLp: poolFullName.split('.')[1] === "b" ? "Baoswap" : "Sushi"
    }
  })
}
