import styled from "styled-components"

export const LoadingInfo = styled.p`
  font-size: 0.8rem;
  visibility: ${props => props.shown ? "visible" : "hidden"};
`

export const LoadingStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LoadingText = styled.p`
  width: 75px;
`
