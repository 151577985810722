import styled from "styled-components"

export const PoolLookup = styled.div``

export const PoolLookupInput = styled.input`
  margin: 0 1rem;
`

export const PoolLookupInputRow = styled.div`
  padding-bottom: 1rem;
`

export const PoolLookupResult = styled.div``

export const ResultsTitle = styled.p`
  font-weight: bold;
`
